<template>
  <div class="price-decomposition">
    <!-- Tickets -->
    <div
      class="description-section"
      v-if="orderDetails.tickets && orderDetails.tickets.length > 0"
    >
      <div class="section-title">
        <span>{{ $t("cart_receipt_tickets") }}</span>
      </div>
      <div
        class="section-subline"
        v-for="(ticket, index) in orderDetails.tickets"
        :key="index"
      >
        <div class="subline-body">
          <template>
            <!-- Spartan+ case -->
            <div v-if="ticket.type_id === spartanPlusTicket.ticketTypeId">
              {{ ticket.registration_choice_name }}
            </div>
            <!-- Other tickets -->
            <div v-else>
              <span>{{
                `${ticket.event_name} - ${ticket.registration_choice_name} (`
              }}</span>
              <span>{{ ticket.amount | currency }}</span>
              <span>{{
                `${ticket.count > 1 ? ` x ${ticket.count}` : ""})`
              }}</span>
            </div>
          </template>
          <span>{{ ticket.total_amount | currency }}</span>
        </div>
        <div class="subline-extra" v-if="ticket.vat">
          <template>
            <span>{{ ticket.vat_percentage + "% VAT" }}</span>
            <span>{{ ticket.vat | currency }}</span>
          </template>
        </div>
      </div>
    </div>
    <!-- Addons -->
    <div
      class="description-section"
      v-if="orderAddons && orderAddons.length !== 0"
    >
      <div class="section-title">
        {{ $t("cart_receipt_add_ons") }}
      </div>
      <div
        class="section-subline"
        v-for="(addon, index) in orderAddons"
        :key="index"
      >
        <div class="subline-body">
          <div>
            <span>{{ `${addon.name} (` }}</span>
            <span>{{ addon.amount | currency }}</span>
            <span>{{ `${addon.count > 1 ? ` x ${addon.count}` : ""})` }}</span>
          </div>
          <span>{{ addon.total_amount | currency }}</span>
        </div>
        <div class="subline-extra" v-if="addon.vat">
          <template>
            <span>{{ addon.vat_percentage + "% VAT" }}</span>
            <span>{{ addon.vat | currency }}</span>
          </template>
        </div>
      </div>
    </div>
    <!-- Subtotal -->
    <div class="description-section">
      <div class="section-title">
        <span>{{ $t("cart_receipt_subtotal") }}</span>
        <span>{{ orderDetails.subtotal | currency }}</span>
      </div>
      <template v-if="orderDetails.fees">
        <div
          class="section-subline"
          v-for="(fee, index) in orderDetails.fees"
          :key="index"
        >
          <div class="subline-body">
            <template>
              <span v-if="fee.count">
                <span>{{ `${fee.name} (` }}</span>
                <span>{{ fee.amount | currency }}</span>
                <span>{{ `${fee.count > 1 ? ` x ${fee.count}` : ""})` }}</span>
              </span>
              <span v-else>{{ fee.name }}</span>
            </template>

            <span>{{ fee.total_amount | currency }}</span>
          </div>
          <div class="subline-extra" v-if="fee.vat">
            <span>{{ fee.vat_percentage + "% VAT" }}</span>
            <span>{{ fee.vat | currency }}</span>
          </div>
        </div>
      </template>
    </div>

    <div class="decomposition-summary" v-if="showTotal">
      <div class="line-discount">
        <span v-if="showDiscount">{{
          orderDetails.originalTotal | currency
        }}</span>
      </div>
      <div class="line-total">
        <div>{{ $t("cart_total_title") }}</div>
        <div class="price-value">{{ orderDetails.total | currency }}</div>
      </div>
      <div class="line-vat">
        <span v-if="orderDetails.vat"
          >{{ $t("including") }} VAT {{ orderDetails.vat | currency }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/config";
import { toNumber } from "lodash";

import { mapGetters } from "vuex";
export default {
  props: {
    showTotal: {
      type: Boolean,
      default: false,
    },
    showDiscount: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["orderDetails", "refoundProtectData", "checkoutRegion"]),
    spartanPlusTicket() {
      let ticket =
        config.checkout[this.checkoutRegion.toLowerCase()]?.spartanPlusTicket;

      if (!ticket) return {};

      return {
        ...ticket,
        ticketTypeId: toNumber(ticket.ticketTypeId),
      };
    },
    orderAddons() {
      return this.orderDetails.addons;
    },
  },
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;
.price-decomposition {
  .description-section {
    display: flex;
    flex-direction: column;

    margin-bottom: 30px;
    .section-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      font-size: 15px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .section-subline {
      display: grid;
      grid-template-columns: 1fr;
      .subline-body,
      .subline-extra {
        display: grid;
        grid-template-columns: 1fr max-content;
        gap: 30px;
      }
      .subline-body {
        line-height: 21px;
        font-size: 13px;
        margin-bottom: 6px;
      }
      .subline-extra {
        // margin-top: 6px;
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 18px;
        color: #646464;
      }
    }
  }
  .decomposition-summary {
    .line-discount {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 2px;
      color: #cc092f;
      text-decoration: line-through;
      text-align: end;
    }
    .line-total {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      font-size: 22px;
      font-weight: 700;
      line-height: 22px;
    }
    .line-vat {
      height: 18px;
      font-size: 12px;
      color: #646464;
      height: 18px;
      text-align: end;
    }
  }
}
</style>